import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { InMemoryScrollingFeature, InMemoryScrollingOptions, provideRouter, withInMemoryScrolling } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { routes } from './app.routes';
import { provideHttpClient } from '@angular/common/http';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { provideEnvironmentNgxMask} from 'ngx-mask';
import LogRocket from 'logrocket';
import { BnNgIdleService } from 'bn-ng-idle';

LogRocket.init('mvv2ld/pif-dealer-gap-handled-survey-prod', {
  network: {
    // Removes VIN and Customer Name due to PII
    responseSanitizer: response => {
      if ("body" in response && typeof(response["body"])=="object") {
        let body = JSON.parse(String(response.body))
        delete body['vin']
        delete body['custName']
        response.body = JSON.stringify(body)
      }
      return response;
    },
  },
});

const scrollConfig: InMemoryScrollingOptions = {
  scrollPositionRestoration: 'top',
  anchorScrolling: 'enabled',
};

const inMemoryScrollingFeature: InMemoryScrollingFeature =
  withInMemoryScrolling(scrollConfig);

export const appConfig: ApplicationConfig = {
  providers: [provideRouter(routes, inMemoryScrollingFeature),provideHttpClient(),importProvidersFrom(BrowserAnimationsModule,BsDatepickerModule.forRoot(),NgxSpinnerModule.forRoot(),TooltipModule.forRoot()),provideEnvironmentNgxMask(),BnNgIdleService]
};

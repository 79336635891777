<div class="gap-container">
    <div class="blueberry-banner">
        <div class="ally-logo"></div>
    </div>
<div class="gap-flex">
    <div  class="submitted-the-form">This form is expired.</div>
    <div class="complete-form">This GAP Refund Form is no longer available for editing.</div>
</div>
<div class="global-footer-desktop">
    <div class="for-questions-conta">
        For questions, contact your Account Executive
        or call us at <span style="color: #0071C4;font-weight: bold;"> 1-866-766-4622.</span>
   </div>
</div>
<div class="rectangle-line"></div>
<div class="rectangle">
    <div class="ally-fina-foot">
        ©2009-2024 Ally Financial, Inc. 
    </div>
    <div class="about-us-terms-of-us">
        Security <span style="margin: 0 30px">Privacy</span>  Legal
    </div>
</div>
</div>
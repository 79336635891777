<div *ngIf="isdataAvailable" class="gap-container">
    <form #gapForm="ngForm" (ngSubmit)="gapForm.form.valid && refundAmt.value <= 5000 && onSubmit()" novalidate>
        <div class="blueberry-banner">
                <div class="ally-logo"></div>
        </div>
        <div class="gap-flex">
            <div class="gap-refund-form">
                GAP Refund Form
            </div>
            <!-- <div class="gap-detail">
                <div class="gap-form" >
                    <label style="margin-left: 75%;"  for="responseDate">Response Date</label>
                    <input type="text" id="responseDate" style="width: 75px;"  class="gap-input-border-none" [(ngModel)]="gapCancel.dealerRespTS" name="ResponseDate">
                </div>
            </div> -->
        </div>
        <div class="gap-detail">
            <div class="gap-form-cust ">
                <label for="customerName"> Customer Name:</label>
                <input type="text" id="customerName" class="gap-input-border-none" [(ngModel)]="gapCancel.custName"
                    name="customerName" readonly data-private>
            </div>
            <div class="gap-form-cust ">
                <label for="vin"> VIN:</label>
                <input type="text" id="vin" class="gap-input-border-none" [(ngModel)]="gapCancel.vin" name="vin" readonly data-private>
            </div>
            <div class="gap-form-cust ">
                <label for="contractDate">Contract Date:</label>
                <input type="text" id="contractDate" class="gap-input-border-none" [(ngModel)]="gapCancel.contractDate"
                    name="contractDate" readonly>
            </div>
            <div class="gap-form-cust ">
                <label for="paidInFullDate">Paid In Full Date:</label>
                <input type="text" id="paidInFullDate" class="gap-input-border-none" [(ngModel)]="gapCancel.pifDate"
                    name="PaidInFullDate" readonly>
            </div>
        </div>
        <div class="gap-form-event">
            <div class="only-complete-this-f">
                <p style="font-weight: bold;">Only complete this form if one of these events applies to the account.</p>
                Select an event.
            </div>
            <div class="gap-event-group-2">
                <div class="event-a" (click)="selectEventOnDivClick('event-a')"
                    [ngStyle]="{'border': gapForm.submitted && responseD.errors ? '0.1em solid #dc3545' : borderColorA }">
                    <input class="event-radio" id="eventa" type="radio" value="Event A" name="response"
                        [(ngModel)]="gapCancel.dealerResponse" required
                        #responseD="ngModel" [ngClass]="{ 'is-invalid': gapForm.submitted && responseD.errors }" />
                    <label for="eventa" class="event-label-1">Cancellation refund was used as a down payment.</label>
                    <label class="event-label-2">The customer used the GAP refund as a down payment on a new transaction.</label>
                    <!--<div *ngIf="gapForm.submitted && responseD.errors" class="invalid-feedback">  (change)="changeBackground($event)"
                        <div  *ngIf="gapForm.submitted && responseD.errors['required']">Please select the appropriate dealer response.</div>
                    </div> -->
                </div>
                <div class="event-b" (click)="selectEventOnDivClick('event-b')"
                    [ngStyle]="{'border': gapForm.submitted && responseD.errors ? '0.1em solid #dc3545' : borderColorB }">
                    <input class="event-radio" id="eventb" type="radio" value="Event B" name="response"
                        [(ngModel)]="gapCancel.dealerResponse" required
                        #responseD="ngModel" [ngClass]="{ 'is-invalid': gapForm.submitted && responseD.errors }" />
                    <label class="event-label-1" for="eventb">Product was cancelled.</label>
                    <label class="event-label-2">The customer cancelled the GAP product before paying the account
                        in full.</label>
                </div>
            </div>
            <div *ngIf="gapForm.submitted && responseD.errors" class="error-message">
                <div  *ngIf="gapForm.submitted && responseD.errors['required']">Select an event. If neither event applies to this account, you don’t need to complete this form.
                </div>
            </div>

        </div>
        <div class="gap-response">
            <!-- <div [formGroup]="form" class="gap-form">
                <label for ="dealerResponse"> Dealer Response</label>
                <select id="dealerResponse" style="border: 0; outline: 0; border-bottom: 2px solid grey;width: 101px;font-weight: bold;" formControlName="response" (change)="changeDealerResponse($event)" [(ngModel)]="gapCancel.dealerResponse">
                    <option disabled >Select Response</option>
                    <option *ngFor="let response of responseList" value="{{response}}" >{{response}}</option>
                </select>
            </div>-->
            <div class="gap-form-response">
                <label class="gap-form-response-label" for="refundAmt"> Refund Amount</label>
                <input type="text" class="form-control gap-form-response-container" id="refundAmt"
                    [(ngModel)]="gapCancel.refundAmt" name="refundAmt" required #refundAmt="ngModel"
                    [ngClass]="{ 'is-invalid': gapForm.submitted && refundAmt.errors }" [ngClass]="{ 'is-invalid': gapForm.submitted && refundAmt.value > 5000}"
                    placeholder="$ 0.00" mask="separator.2" decimalMarker="." thousandSeparator="" separatorLimit="9999"
                   >
                <div *ngIf="gapForm.submitted && refundAmt.errors" class="error-message">
                    <div *ngIf="refundAmt.errors['required']">Enter a dollar amount between $0 and $5,000.</div>
                </div>
                <div *ngIf="gapForm.submitted " class="error-message" [ngStyle]="{'height': refundAmt.value < 0 || refundAmt.value > 5000 ? '16px' : '0px'}">
                    <div *ngIf="refundAmt.value < 0 || refundAmt.value > 5000 ">Enter a dollar amount between $0 and $5,000.</div>
                </div>
            </div>
            <div class="gap-form-response">
                <label class="gap-form-response-label" for="cancellationDate"> Cancellation Effective Date</label>
                <!--<input type="date" class="form-control gap-form-response-container" id="cancellationDate"
                    [(ngModel)]="gapCancel.cancellationDate" name="cancellationDate"
                    required #cancellationDate="ngModel"
                    [ngClass]="{ 'is-invalid': gapForm.submitted && cancellationDate.errors }"
                    max="{{gapCancel.pifDate | date: 'yyyy-MM-dd'}}" min="{{gapCancel.contractDate | date: 'yyyy-MM-dd'}}" (keydown)="disableDate()">
                 
                <div *ngIf="gapForm.submitted && cancellationDate.errors" class="invalid-feedback">
                    <div *ngIf="cancellationDate.errors['required']">Enter a date between [Contract date] and [Paid In Full date] in mm/dd/yyyy format</div>
                </div>-->
                <div style="display: flex;">
                    <input type="text" class="form-control gap-form-response-container" id="cancellationDate"
                        [(ngModel)]="gapCancel.cancellationDate" name="cancellationDate"
                        required #cancellationDate="ngModel"
                        [ngClass]="{ 'is-invalid': gapForm.submitted && cancellationDate.errors }"
                        [bsConfig]="{containerClass:'theme-dark-blue',showWeekNumbers:false}" #dp="bsDatepicker" autocomplete="off"
                        [maxDate]="maxDate" [minDate]="minDate" placeholder="mm/dd/yyyy" bsDatepicker (keydown)="disableDate()"> 
                       <div class="input-wrapper" (click)="dp.toggle()" [attr.aria-expanded]="dp.isOpen"
                       type="button"></div>
                </div>
                <div *ngIf="gapForm.submitted && cancellationDate.errors" class="error-message">
                    <div *ngIf="cancellationDate.errors['required']">Enter a date between [Contract Date] and [Paid in Full Date] in mm/dd/yyyy format .</div>
                </div>
            </div>
            <!--<div class="gap-form-response">
                <label for="methodFee">Method Fee</label>
                <input type="text" class="form-control gap-input-border" id="methodFee"
                    [(ngModel)]="gapCancel.methodFee" name="methodFee" required #methodFee="ngModel"
                    [ngClass]="{ 'is-invalid': gapForm.submitted && methodFee.errors }">
                <div *ngIf="gapForm.submitted && methodFee.errors" class="invalid-feedback">
                    <div *ngIf=" gapForm.submitted && methodFee.errors['required']">Method Fee is required</div>
                </div>
            </div>-->
            <div class="gap-form-response">
                <label class="gap-form-response-label" for="dealershipName"> Dealership Name</label>
                <input type="text" class="form-control gap-form-response-container" id="dealershipName"
                    [(ngModel)]="gapCancel.dealerName" name="dealershipName" [tooltip]="tooltipTmpl" placement="right" autocomplete="off" readonly>
            </div>
            <div class="gap-form-response">
                <label class="gap-form-response-label" for="name"> Name</label>
                <input type="text" class="form-control gap-form-response-container" id="name" [(ngModel)]="gapCancel.name"
                    name="name" readonly data-private>
            </div>
            <div class="gap-form-response">
                <label class="gap-form-response-label" for="role"> Role</label>
                <input type="text" class="form-control gap-form-response-container" id="role" [(ngModel)]="gapCancel.role"
                    name="role" readonly>
            </div>
        </div>
       
            <div class="based-on-this-event">
                <div>
                    Based on this event, Ally should not pay a GAP refund to the customer.
                </div>

                <div style="margin-top: 16px;">
                    I acknowledge that Ally may request supporting documentation to validate this information, and I will
                    provide it upon request.
                </div>
                <div style="margin-top: 16px;">
                    As an authorized representative of the dealership, I confirm that the event selected applies to the
                    referenced account.
                </div>
            </div>
            <div class="gap-submit">
                <button class="btn-submit" type="submit">Submit</button>
            </div>
            <div class="global-footer-desktop">
                <div class="for-questions-conta">
                    For questions, contact your Account Executive
                    or call us at <span style="color: #0071C4;font-weight: bold;"> 1-866-766-4622.</span>
                </div>
            </div>
            <div class="rectangle-line"></div>
            <div class="rectangle">
                <div class="ally-fina-foot">
                    ©2009-2024 Ally Financial, Inc. 
                </div>
                <div class="about-us-terms-of-us">
                    Security <span style="margin: 0 30px">Privacy</span>  Legal
                </div>
            </div>
    </form>
</div>
<ngx-spinner *ngIf="isMobileLayout" type="line-spin-clockwise-fade" size="small"></ngx-spinner>
<ngx-spinner *ngIf="!isMobileLayout" type="line-spin-clockwise-fade" size="medium"></ngx-spinner>
<ng-template #tooltipTmpl>
    <div>
        {{gapCancel.dealerName}}
    </div>
</ng-template>
import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { InMemoryScrollingFeature, InMemoryScrollingOptions, provideRouter, withInMemoryScrolling } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { routes } from './app.routes';
import { provideHttpClient } from '@angular/common/http';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { provideEnvironmentNgxMask} from 'ngx-mask';
import LogRocket from 'logrocket';
import { BnNgIdleService } from 'bn-ng-idle';

LogRocket.init('mvv2ld/pif-dealer-gap-handled-survey-prod', {

network: {
    // Removes VIN and Customer Name due to PII
    responseSanitizer: response => {
        if ("body" in response && typeof(response["body"])=="string") {
          let body = JSON.parse(String(response.body));
          if (body['vin']) { body['vin'] = '[REDACTED]';}
          if (body['custName']) { body['custName'] = '[REDACTED]';}
          if (body['token']) { body['token'] = '[REDACTED]';}
          if (body['access_token']) { body['access_token'] = '[REDACTED]';}
          response.body = JSON.stringify(body);
        }
      if ("url" in response) {
          let sanitizedUrl = String(response.url);
          let urlRegex = /http[s]:\/\/(.*)/;
          let matches = sanitizedUrl.match(urlRegex);
          // redact the value of the query parameter secret_key
          if (matches) {sanitizedUrl = sanitizedUrl.replace(matches[1], '***.com');}
          response.url = sanitizedUrl;
        }
      return response;
    },
  requestSanitizer: request => {
        request.headers['Authorization'] = '[REDACTED]';
        if ("body" in request && typeof(request["body"])=="string") {
          try {
              let body = JSON.parse(String(request.body));
              if (body['token']) { body['token'] = '[REDACTED]';}
              request.body = JSON.stringify(body);
          } catch (e) {
              console.log("Body is not JSON.");
          }

        }
        // if the url contains 'token'
        if (request.url.toLowerCase().indexOf('http') !== -1) {
          let sanitizedUrl = request.url;
          let urlRegex = /http[s]:\/\/(.*)/;
          let matches = sanitizedUrl.match(urlRegex);
          // redact the value of the query parameter secret_key
          if (matches) {sanitizedUrl = sanitizedUrl.replace(matches[1], '***.com');}
          request.url = sanitizedUrl;
        }
        return request;
      },
  },
  browser: {
    urlSanitizer: url => {
      let sanitizedUrl = url;
      let urlRegex = /http[s]*:\/\/[^\/]+(\/.+)/;
      let matches = sanitizedUrl.match(urlRegex);
      // redact the value of the query parameter secret_key
      if (matches) {sanitizedUrl = sanitizedUrl.replace(matches[1], '***.com');}
      // make sure you return the sanitized URL string
      return sanitizedUrl;
    },
  },
});

const scrollConfig: InMemoryScrollingOptions = {
  scrollPositionRestoration: 'top',
  anchorScrolling: 'enabled',
};

const inMemoryScrollingFeature: InMemoryScrollingFeature =
  withInMemoryScrolling(scrollConfig);

export const appConfig: ApplicationConfig = {
  providers: [provideRouter(routes, inMemoryScrollingFeature),provideHttpClient(),importProvidersFrom(BrowserAnimationsModule,BsDatepickerModule.forRoot(),NgxSpinnerModule.forRoot(),TooltipModule.forRoot()),provideEnvironmentNgxMask(),BnNgIdleService]
};


export class GapCancellation {
    custFirstName!: string ;
    custLastName!: String ;
    custName!: String;
    vin!: String ;
    contractDate!: string ;
    pifDate!: string ;
    responseDate!: String |null;
    dealerResponse!: String;
    refundAmt!: Number;
    cancellationDate!: String;
    methodFee!: String;
    dealerName!: String;
    tokenStatus!: string;
    token!: string;
    dealerRespTS!: string |null;
    name!: String;
    role!: String;
    saveStatus!: String;
    errorMessages!: String;
}

import { HttpClient,HttpHeaders,HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GapCancellation } from '../model/gap-cancellation.model';
import { Observable, map, switchMap } from 'rxjs';
import { environment } from '../../environments/environment';
import { ResponseData } from '../model/response-data.model';
import { GapResponse } from '../model/gap-response.model';

@Injectable({
  providedIn: 'root'
})
export class GapApiService {
  resPonseData: ResponseData= new ResponseData;
  constructor(private http :HttpClient) {

  }

  public  checkTokenStatus(id: any){
    const httpOptions ={
      headers : new HttpHeaders({
        'Content-type': 'application/x-www-form-urlencoded',
        'Authorization': 'Basic '+btoa(environment.clientId+':'+environment.clientValue)
      })
    };

    let data = 'grant_type=client_credentials';
   return this.http.post<ResponseData>(environment.tokenUrl,data,httpOptions).pipe(switchMap(
      (response :ResponseData) =>{
        this.resPonseData = response;
        const headers = { 'Authorization': this.resPonseData.token_type+' '+this.resPonseData.access_token }
        let queryParams = new HttpParams();
        queryParams=queryParams.append("token",id);
        return this.http.get<GapCancellation>(environment.gapGetUrl,{headers:headers,params:queryParams})
      }))
  }

  public  submitGapRecDetailForDealer(gapResponse: GapResponse){
    const httpOptions ={
      headers : new HttpHeaders({
        'Content-type': 'application/x-www-form-urlencoded',
        'Authorization': 'Basic '+btoa(environment.clientId+':'+environment.clientValue)
      })
    };
    let data = 'grant_type=client_credentials';
    return this.http.post<ResponseData>(environment.tokenUrl,data,httpOptions).pipe(switchMap(
      (response :ResponseData) =>{
        this.resPonseData = response;
        const headerOptions ={
          headers : new HttpHeaders({
            'Content-type': 'application/json',
            'Authorization': this.resPonseData.token_type+' '+this.resPonseData.access_token
          }),
         // responseType : 'text' as 'text'
        };
        return this.http.post<GapCancellation>(environment.gapPostUrl,gapResponse,headerOptions)
      }))
  }

}

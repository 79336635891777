import { Routes } from '@angular/router';
import { ErrorComponent } from './pages/error/error.component';
import { DealerResponseSubmitComponent } from './pages/dealer-response-submit/dealer-response-submit.component';
import { LinkExpiredComponent } from './pages/link-expired/link-expired.component';
import { DlrResCompleteComponent } from './pages/dlr-res-complete/dlr-res-complete.component';
import { GapCancelFormComponent } from './pages/gap-cancel-form/gap-cancel-form.component';
import { TokenNotFoundComponent } from './pages/token-not-found/token-not-found.component';
import { UserSessionExpiredComponent } from './pages/user-session-expired/user-session-expired.component';

export const routes: Routes = [
    {
        path : "gap-cancellation",
        component : GapCancelFormComponent
    },
    {
        path : "gap-cancellation/dealer-response",
        component : DealerResponseSubmitComponent
    },
    {
        path : "gap-cancellation/dlr-res-complete",
        component : DlrResCompleteComponent
    },
    {
        path : "error",
        component : ErrorComponent
    },
    {
        path : "gap-cancellation/link-expired",
        component : LinkExpiredComponent
    },
    {
        path : "gap-cancellation/token-not-found",
        component : TokenNotFoundComponent
    },
    {
        path : "gap-cancellation/session-expired",
        component : UserSessionExpiredComponent
    }
];

import { Component } from '@angular/core';

@Component({
  selector: 'app-dealer-response-submit',
  standalone: true,
  imports: [],
  templateUrl: './dealer-response-submit.component.html',
  styleUrl: './dealer-response-submit.component.css'
})
export class DealerResponseSubmitComponent {

}

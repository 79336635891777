import { CommonModule, DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, NgForm, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { GapCancellation } from '../../model/gap-cancellation.model';
import { GapApiService } from '../../services/gap-api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { GapResponse } from '../../model/gap-response.model';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import LogRocket from 'logrocket';
import { BnNgIdleService } from 'bn-ng-idle';

@Component({
  selector: 'app-gap-cancel-form',
  standalone: true,
  imports: [CommonModule, RouterOutlet, FormsModule,NgxSpinnerModule,BsDatepickerModule,TooltipModule,NgxMaskDirective,NgxMaskPipe],
  templateUrl: './gap-cancel-form.component.html',
  styleUrl: './gap-cancel-form.component.css'
})
export class GapCancelFormComponent implements OnInit {
  isdataAvailable:boolean =false;
  isMobileLayout:boolean = false;
  id: any
  gap: GapCancellation = new GapCancellation;
  gapCancel: GapCancellation = new GapCancellation;
  currentDate!: string | null;
  gapResponse: GapResponse = new GapResponse;
  minDate!: Date;
  maxDate!: Date;
  borderColorA='0.1em solid #C0C8D6';
  borderColorB='0.1em solid #C0C8D6';
  eventBChecked : boolean = false;
  eventAChecked : boolean = false;
  gapCancelResponse: GapCancellation = new GapCancellation;

  constructor(
    private route: ActivatedRoute, private gapService: GapApiService,
    private router: Router ,public fb :FormBuilder,
    private spinner: NgxSpinnerService,
    private bnIdle: BnNgIdleService
  ){
    // Associates the LogRocket session with the PIF token provided in the URL
    var rx = /token=(.*)/g
    var token = rx.exec(this.router.url)
    if (token)
      LogRocket.identify(token[1].substring(0, 23))
  }

  ngOnInit(): void {
  this.eventBChecked = false;
  this.eventAChecked = false;
    if (window.screen.width <= 768) { // for mobile & desktop spinner
      this.isMobileLayout = true;
    }else{
      this.isMobileLayout = false;
    }
    this.bnIdle.startWatching(300).subscribe((res) =>{
      if(res){
        this.router.navigate(['gap-cancellation/session-expired']);
      }
    })
    const datePipe = new DatePipe('en-Us');
    this.currentDate = datePipe.transform(new Date(), 'yyyy-MM-dd');
    //this.id = this.route.snapshot.paramMap.get('id');
    this.id = this.route.snapshot.queryParamMap.get('token');
    this.spinner.show();

    this.gapService.checkTokenStatus(this.id).subscribe((response: GapCancellation) => {
      this.gap = response;
      if (this.gap.tokenStatus == 'ACTIVE') {
        this.isdataAvailable = true;
        this.gap.token = this.id;
        this.gapCancel = this.gap
        //console.log('Actual Contract & pif date --> '+this.gap.contractDate+' --> '+this.gap.pifDate)
        var cDate = new Date(this.gap.contractDate);
        cDate.setMinutes( cDate.getMinutes() + cDate.getTimezoneOffset() );
        var pDate = new Date(this.gap.pifDate);
        pDate.setMinutes( pDate.getMinutes() + pDate.getTimezoneOffset() );
        //console.log('cdate with any timezone--> '+cDate + 'LocalTimezone date '+new Date(Date.parse(this.gap.contractDate)))
        /*this.minDate = new Date(Date.parse(this.gap.contractDate));
        this.maxDate = new Date(Date.parse(this.gap.pifDate));*/
        this.minDate = cDate;
        this.maxDate = pDate;
        this.spinner.hide();
      } else if (this.gap.tokenStatus == 'SUBMITTED') {
        this.router.navigate(['gap-cancellation/dlr-res-complete']);
        this.spinner.hide();
      }
      else if (this.gap.tokenStatus == 'EXPIRED') {
        this.router.navigate(['gap-cancellation/link-expired']);
        this.spinner.hide();
      }
      else if (this.gap.tokenStatus == 'NOT_FOUND') {
        this.router.navigate(['gap-cancellation/token-not-found']);
        this.spinner.hide();
      }
    },(err:HttpErrorResponse) => {
      this.router.navigate(['/error']);
      this.spinner.hide();
   });

  }

  onSubmit() {
    this.spinner.show();
    //console.log('selected Cancellation date with any timezone --> '+ this.gapCancel.cancellationDate.toLocaleString().substring(0, this.gapCancel.cancellationDate.toLocaleString().indexOf(",")))
    //console.log('selected Cancellation date with localTimezone--> '+this.gapCancel.cancellationDate);
    var newarr = this.gapCancel.cancellationDate.toLocaleString().substring(0, this.gapCancel.cancellationDate.toLocaleString().indexOf(",")).split("/");
    var month = newarr[0].length < 2 ? '0'+newarr[0] :newarr[0] ;
    var day = newarr[1].length < 2 ? '0'+newarr[1] :newarr[1] ;
    var year = newarr[2];
    var canDate= month +'/'+day+'/'+year;
    //console.log('formatted Cancellation date with any timezone --> '+canDate)
    this.gapResponse.cancellationDate = canDate;
    this.gapResponse.dealerResponse = this.gapCancel.dealerResponse;
    this.gapResponse.refundAmt = this.gapCancel.refundAmt;
    this.gapResponse.token = this.gapCancel.token;
    this.gapService.submitGapRecDetailForDealer(this.gapResponse).subscribe(response => {
      this.gapCancelResponse = response;
      if(this.gapCancelResponse.saveStatus === 'SUCCESS'){
        this.router.navigate(['gap-cancellation/dealer-response']);
        this.spinner.hide();
      }else if(this.gapCancelResponse.saveStatus === 'ALREADY_SUBMITTED'){
        this.router.navigate(['gap-cancellation/dlr-res-complete']);
        this.spinner.hide();
      }else if(this.gapCancelResponse.saveStatus === 'EXPIRED'){
        this.router.navigate(['gap-cancellation/link-expired']);
        this.spinner.hide();
      }else{
        this.router.navigate(['/error']);
        this.spinner.hide();
      }
    }, (err:HttpErrorResponse) => {
      console.log(err)
      this.router.navigate(['/error']);
      this.spinner.hide();
    });
  }

  selectEventOnDivClick(event:string){
    //console.log(event +' selected')
    if(event === 'event-a'){
      this.eventAChecked= true;
      this.eventBChecked= false;
      this.borderColorA= '0.1em solid #008CBA';
      this.borderColorB= '0.1em solid #C0C8D6'
      this.gapCancel.dealerResponse = 'Event A'
    }else if(event === 'event-b'){
      this.eventAChecked= false;
      this.eventBChecked= true;
      this.borderColorB= '0.1em solid #008CBA';
      this.borderColorA= '0.1em solid #C0C8D6';
      this.gapCancel.dealerResponse = 'Event B'
    }
  }
  setTwoNumberDecimal(e:any) {
    e.target.value = parseFloat(e.target.value).toFixed(2);
    this.gapCancel.refundAmt = e.target.value;
  }

  disableDate() {
   return false;
  }
}
